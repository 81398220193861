<template>
  <gf-base v-loading="loading">
    <el-card class="base-card" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0px', pageBreakInside: 'auto !important'}">
      <Media :column-filter="columnFilter"/>
      <el-row class="body-row">
        <el-col style="overflow: initial !important">
          <div class="header text-center mt-5"><span class="font-size-lg font-weight-bolder text-dark">Product List</span></div>
          <el-row type="flex" class="base-card__description">
            <table class="base-card__description__table" >
              <thead class="thead">
                <tr>
                  <th :class="thprod" style="width: 40%" v-if="columnFilter[0].show">Product Name</th>
                  <th :class="thcent" v-if="columnFilter[1].show">SKU</th>
                  <th :class="thcent" v-if="columnFilter[2].show">Stock On- Hold</th>
                  <th :class="thcent" v-if="columnFilter[3].show">Commited <br/>Stocks</th>
                  <th :class="thcent" v-if="columnFilter[4].show">Created At</th>
                  <th :class="thcent" v-if="columnFilter[5].show">Last Updated</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in variants" :key="item.id">
                  <td :class="tdprod" v-if="columnFilter[0].show"><span :class="span">{{ item.product.name }}</span><br/>{{ item.name }}</td>
                  <!-- <td :class="td" v-if="columnFilter[1].show"></td> -->
                  <td :class="tdcent" v-if="columnFilter[1].show">{{ item.sku }}</td>
                  <td :class="tdcent" v-if="item.is_pack && columnFilter[2].show">--</td>
                  <td :class="tdcent" v-if="!item.is_pack && columnFilter[2].show">{{ item.stocks.map(s => parseFloat(s.value || 0) - parseFloat(s.commited || 0)).reduce((o, n) => o + n, 0) }}</td>
                  <td :class="tdcent" v-if="item.is_pack && columnFilter[3].show">--</td>
                  <td :class="tdcent" v-if="!item.is_pack && columnFilter[3].show">{{ item.stocks.map(s => parseFloat(s.commited || 0)).reduce((o, n) => o + n, 0) }}</td>
                  <td :class="tdcent" v-if="columnFilter[4].show">{{ $DateFormat(item.created_at) }}</td>
                  <td :class="tdcent" v-if="columnFilter[5].show">{{ $DateFormat(item.updated_at) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center"><span class="font-size-xs font-weight-light text-dark"><i>*** Nothing Follows ***</i></span></div>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
  </gf-base>
</template>

<style lang="scss">
.el-popover {
  background-color: rgba(0,0,0,0.4);
  border: none;
  border-radius: 0px;
}

</style>

<style lang="scss" scoped>
.body-row {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.col {
  flex-grow: 1;
  height: 0;
  overflow: auto;
}

.base-card {
  overflow: auto;
  font-family: Poppins, Helvetica, sans-serif !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__sticky {
    padding-top: 20px;
    display: flex;
    position: fixed;
    width: 100%;
    flex-direction: row;
    z-index: 1;

    .el-popover { padding-left: 0px !important; }

    &__preview { display: flex; }

    &__print { display: flex; }
  }

  &__description {
    display: flex;
    box-sizing: border-box;
    padding: 1% 5% 3% 5%;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;

    &__table { border-collapse: collapse; }
  }
}
</style>

<script>
import VariantService from '@/services/v1/Variant'
import Media from './components/Media.vue'

export default {
  components: {
    Media
  },
  data () {
    return {
      variants: [],
      vrService: null,
      isVisible: false,
      loading: false,

      columnFilter: [
        { label: 'Product Name', show: true },
        { label: 'SKU', show: true },
        { label: 'Stock On Hold', show: true },
        { label: 'Commited Stock', show: true },
        { label: 'Created At', show: false },
        { label: 'Updated At', show: false }
      ],

      page: {
        size: 1,
        number: 1
      },
      total: 0,

      // Styles
      thprod: 'font-size-xs font-weight-bolder text-dark text-left border border-dark p-2',
      thcent: 'font-size-xs font-weight-bolder text-dark text-center border border-dark p-1',
      tdprod: 'font-size-xs font-weight-normal text-dark text-left border border-dark p-2',
      tdcent: 'font-size-xs font-weight-normal text-dark text-center border border-dark p-1',
      span: 'font-weight-bolder'
    }
  },

  methods: {
    handleFilter () {
      this.isVisible = false
    },
    handleBack () {
      this.$router.back()
    },
    async getVariants () {
      this.loading = true
      try {
        const vrService = new VariantService()
        const result = await vrService.list(this.page)
        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.total = result.data.count

        this.page.size = this.total
        const final = await vrService.list(this.page)
        this.variants = final.data.rows
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Inventory' },
      { title: 'Variants' },
      { title: 'Print' }
    ])

    this.getVariants()
  }
}
</script>
