<template>
  <div class="column-filter">
    <el-dropdown :hide-on-click="false" trigger="click">
        <gf-button class="mr-2" size="sm" type="primary">
        <template slot="icon">
          <span class="svg-icon svg-icon-white svg-icon-sm">
            <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <rect height="24" width="24" x="0" y="0"/>
                    <path d="M5,4 L19,4 C19.2761424,4 19.5,4.22385763 19.5,4.5 C19.5,4.60818511 19.4649111,4.71345191 19.4,4.8 L14,12 L14,20.190983 C14,20.4671254 13.7761424,20.690983 13.5,20.690983 C13.4223775,20.690983 13.3458209,20.6729105 13.2763932,20.6381966 L10,19 L10,12 L4.6,4.8 C4.43431458,4.5790861 4.4790861,4.26568542 4.7,4.1 C4.78654809,4.03508894 4.89181489,4 5,4 Z" fill="#000000"/>
                </g>
            </svg>
          </span>
        </template>
        Column Filter
      </gf-button>
      <el-dropdown-menu slot="dropdown">
        <span class="font-size-xs font-weight-bolder" style="padding: 0 20px">Show Columns</span>
        <el-dropdown-item v-for="field in columnFilter" :key="field.label">
          <el-checkbox v-model="field.show"><span class="font-size-xs font-weight-bolder">{{ field.label }}</span></el-checkbox>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <gf-button size="sm" style="margin-right: 10px" type="primary" @click="print">
      <template slot="icon">
        <span class="svg-icon svg-icon-white svg-icon-sm">
          <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0"/>
                <path d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z" fill="#000000"/>
                <rect fill="#000000" height="2" opacity="0.3" rx="1" width="8" x="8" y="2"/>
            </g>
          </svg>
        </span>
      </template>
      Print
    </gf-button>
    <BackButton @click="back"/>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/components/_variables.bootstrap.scss';

@media print {
  .column-filter { display: none !important }
  .header { margin-top: 0px !important; margin-bottom: 5px !important; }
  .options { display: none !important; }

  // Outside the el-card

  body {
    overflow: initial !important;
    height: initial;
    background: #fff !important;
  }

  .el-main { overflow: initial !important; }
  .topbar { display: none; }
  .base-container { margin-top: -65px; background: #fff !important; }
  .base-container__row { margin: 0 !important; padding: 0 !important; }
  .base-container__header { display: none !important; }
  .base-container__row { margin: 0px !important; }
  .base-container__row--space-bottom { margin-bottom: 0px !important; }
  .base-container__row--fill-height { margin: 0px !important; }

  // Start of Main Body

  .el-card {
    box-shadow: none !important;
    border-radius: 0px  !important;
    border: none !important;
    background-color: #FFFF;
  }

  .base-card {
    overflow: initial !important;
    page-break-inside: auto !important;
    box-shadow: none !important;
    z-index: 1;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &__header { width: 100%; text-align:center !important; }
  }

  .col { overflow: initial !important; }
  .el-card__body { width: 100%; }

  // Table Page Breaking

  table { page-break-inside:auto !important; }

  th {
    font-size: 10px !important;
  }

  tr td {
    font-size: 10px !important;
    page-break-inside: avoid !important;
    page-break-after:auto !important;
  }

  tr {
    border-bottom: 1px rgba($color: #000, $alpha: 0.45) solid !important;
    margin-bottom: 1px !important;
  }

  thead { display:table-header-group !important; }

  .base-card__description {
    padding-top: 0px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

.column-filter {
    display: flex;
    position: absolute;
    top: 40px;
    right: 60px;
    z-index: 1;

  .menu {
    cursor: pointer;
    padding: 6px;
    border-radius: 5.5px;
    background: $primary-light;
    display: flex;
    align-items: center;
    transition: .3s all;

    &:hover {
      background: $primary;

      & > i {
        color: #fff;
      }

      & > span {
        color: #fff !important;
      }
    }

    i {
      transition: .2s all;
      font-size: 20px;
      color: $primary;
    }

    span {
      transition: .2s all;
      color: $primary;
    }
  }
}
</style>

<script>
import BackButton from '@/component-v2/BackButton'

export default {
  components: {
    BackButton
  },
  props: {
    columnFilter: Array
  },

  methods: {
    print () {
      document.title = 'Product Variant List'
      window.print()
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
